import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/services/services.module.scss';

class Engineering extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const isEngineering = 'Engineering';
    const headingEngineering = document.querySelector('.init_a_headingEngineering');
    const introServices = document.querySelector('.init_a_introServices');
    setTimeout(() => {
      this.initAnimation.heading(headingEngineering, isEngineering);
      this.initAnimation.intro(introServices);
    }, 1000);
  }

  serviceList = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const serviceItem = document.querySelectorAll('.init_a_serviceItem');
      this.initAnimation.list(serviceItem);
      unobserve();
    }
  };

  serviceSkill = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const strategySkill = document.querySelector('.init_a_serviceSkill');
      this.initAnimation.list(strategySkill);
      unobserve();
    }
  };

  render() {
    const EngineeringQuery = graphql`
      {
        illustration: file(relativePath: { eq: "illustration/services_engineering.png" }){
          childImageSharp {
            fluid(maxWidth: 703, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        badge: file(relativePath: { eq: "illustration/icon_engineering.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const optionsSkill = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={EngineeringQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header transition={transition} current="services" />
              <ParallaxProvider>
                <main className={`services ${Style.services}`}>
                  <section className={`top ${Style.top}`}>
                    <div className={Style.left}>
                      <h1 className="heading">
                        <span className="text textEngineering">Engineering</span>
                        <span className="clip clipEngineering init_a_headingEngineering">Engineering</span>
                      </h1>
                      <p className={`intro init_a_introServices ${Style.intro}`}>エンジニアは「目に見えない」情報設計を担っています。ロード時間やアクセシビリティなど、ユーザーのストレスに配慮したり、レスポンシブやPWAなど変化の激しいウェブ環境に対応するべく、R&Dに力を入れています。</p>
                    </div>
                    <Parallax className={`${Style.illustration} ${Style.illustrationEngineering}`} x={[5, -5]} tagInner="figure">
                      <Img
                        className={Style.illustrationImg}
                        fluid={data.illustration.childImageSharp.fluid}
                        alt="Engineering"
                      />
                    </Parallax>
                  </section>
                  <Observer {...options} onChange={this.serviceList}>
                    <section className={`sectionContent sectionContentFirst ${Style.service}`}>
                      <div className="scroll scrollTop" />
                      <div className={`inner ${Style.inner}`}>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            先端技術による
                            <br />
                            パフォーマンス
                          </dt>
                          <dd className={Style.serviceItemDescription}>OSやデバイス、トレンド等の環境変化が激しいウェブメディアにおいて、常に最先端のウェブ開発環境を取り入れて対応しています。また、サイトのシステム設計やクローラビリティ（検索エンジンへの最適化）など、見た目の印象ではなく「目に見えない部分」にも配慮したウェブ開発を行なっています。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            システム化と
                            <br />
                            作業効率の改善
                          </dt>
                          <dd className={Style.serviceItemDescription}>先端技術を導入することで、ウェブサイトの機能を拡張し、単純作業・大量・高速など、コンピュータが得意とする業務を積極的にシステム化し、業務の効率化を提案しています。また、ウェブサイトの管理者側が作業を行う管理画面の設計や情報入力作業などにおいても業務負荷を改善できる設計を心がけています。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            セキュリティ
                            <br />
                            リスク対策
                          </dt>
                          <dd className={Style.serviceItemDescription}>IoTやパーソナルデバイスの急速な普及に伴い、ウェブサイト開発においてもセキュリティリスクが増大し、多層防御の時代を迎えています。ソフトウェアの定期アップデートやバックアップ、脆弱性対策、不正ログインや認証対策など、安全・安定したサイト運用を実現する運用保守プランを提案しています。</dd>
                        </dl>
                      </div>
                    </section>
                  </Observer>
                  <Observer {...optionsSkill} onChange={this.serviceSkill}>
                    <section className={`sectionContent ${Style.skillup}`}>
                      <div className={`inner init_a_serviceSkill ${Style.inner}`}>
                        <div className={Style.skillupIllustration}>
                          <Img
                            className={Style.illustrationImg}
                            fluid={data.badge.childImageSharp.fluid}
                            alt="Mediagrowth"
                          />
                        </div>
                        <div className={Style.right}>
                          <h4>「PWA」の導入支援</h4>
                          <p>PWAとは、モバイル時代における先進技術としてGoogleが提唱した、ユーザーの体験（UX）向上を目的とした概念及び技術です。ウェブサイトとアプリの両方の利点を取り入れ、アプリのようなウェブサイトを実現できます。</p>
                        </div>
                      </div>
                    </section>
                  </Observer>
                </main>
              </ParallaxProvider>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Engineering;
